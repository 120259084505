import { adminManager } from "@/services/admin/admin-manager";
import { defineStore } from "pinia";

export const useNewsStore = defineStore({
  id: "News",
  state: () => {
    return {
      news: [],
      newsItem: null,
      tagCorrespondance: [
        { id: 10, name: "Bug", color: "redBadge" },
        { id: 11, name: "Actualités", color: "blueBadge" },
        { id: 12, name: "Maintenance", color: "redBadge" },
        { id: 13, name: "Nouvelle fonctionnalité", color: "greenBadge" },
        { id: 14, name: "Tutoriel", color: "yellowBadge" },
      ],
    };
  },

  getters: {
    findNewsItem(state) {
      return (id) => state.news.find((news) => news.id === Number(id));
    },
  },

  actions: {
    fetchNews() {
      return adminManager.list("news", false).then((news) => {
        this.news = news;
      });
    },

    toggleNews(id) {
      this.newsItem = this.findNewsItem(id);
    },
  },
});
